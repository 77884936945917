.container {
    margin: 0 auto;
    padding: 0 15px;

    @include responsive ('lg') {
        width: $container-width;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

*[class*="col-"] {
    width: 100%;
    box-sizing: border-box;
}


// Columns for all breakpoints

@each $breakpoint, $width in $breakpoints {
    @media (min-width: #{$width}) {
        @for $i from 1 through 12 {
            .col-#{$breakpoint}-#{$i} {
                margin-right: 30px;
                
                // Subtract 30px to prevent unnecessary wrapping
                width: calc(#{$i / 12 * 100%} - 30px);
            }
        }
    }
}