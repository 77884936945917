
// Colors

$color-green: #78AB9D;
$color-green-dark: darken($color-green, 20%);

$color-brown: #C7A195;
$color-brown-light: #FAF4F2;

$color-gray: #777777;
$color-gray-light: #DDDDDD;
$color-gray-dark: #111111;


// Breakpoints

$breakpoints: (
    "md": 768px,
    "lg": 992px
);


// Sizes 

$container-width: 1110px;