

// Input fields

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='search']
 {
    display: block;
    width: 100%;
    border: 1px solid $color-gray-light;
    line-height: 6rem;
    text-indent: 2rem;
    margin-bottom: 3rem;
    outline: none;
    @include transition(border);

    &:focus {
        border: 1px solid $color-green;
    }
}

textarea {
    border: 1px solid $color-gray-light;
    min-height: 12rem;
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 3rem;
    outline: none;
    @include transition(border);

    &:focus {
        border: 1px solid $color-green;
    }
}


// Labels

label[for] {
    @extend h5;
    display: block;
    margin-bottom: 1rem;
}


// Buttons

.button {
    display: inline-block;
    border: none;
    border-radius: 0;
    background-color: $color-gray-light;
    line-height: 6rem;
    cursor: pointer;
    padding: 0 3rem;
    font-weight: bold;
    color: $color-gray-dark;
    margin-right: 1rem;
    outline-color: $color-green;
    text-transform: uppercase;
    @include transition(background-color);

    &:hover {
        background-color: lighten($color-gray-dark, 70%);
    }

    &.button-green {
        color: white;
        background-color: $color-green;

        &:hover {
            background-color: darken($color-green, 10%);
        }
    }

    &:disabled {
        background-color: $color-gray-light;
        cursor: default;
        
        &:hover {
            background-color: $color-gray-light;
        }
    }
}