
/* Headings */

h1, h2, h3, h4, h5 {
    color: $color-gray-dark;
    text-transform: uppercase;
}

h1, h2, h3 {
    font-weight: bold;
    color: $color-brown;
}

h4, h5 {
    font-family: 'Sanchez', serif;
}


h1 {
    font-size: 8rem;
}

h2 {
    font-size: 6.4rem;

    @media (max-width: map-get($map: $breakpoints, $key: 'md')) {
        font-size: 8vw;
    }
}

h3 {
    font-size: 4.8rem;
    
    @media (max-width: map-get($map: $breakpoints, $key: 'md')) {
        font-size: 6vw;
    }
}

h4 {
    font-size: 2.4rem;
}

h5 {
    font-size: 1.6rem;
}


/* Links */

a {
    color: $color-green-dark;
}


// Form error message

p.error {
    color: $color-brown;
    font-weight: bold;
}