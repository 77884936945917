@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sanchez&display=swap);

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    color: $color-gray;
    line-height: 1.4;
    font-family: 'Montserrat', sans-serif;
}