.box {
    border: 1px solid $color-green;
    outline: 1px solid $color-green;
    outline-offset: 8px;
    margin: 10px;
    padding: 3rem;
}

.box-invisible {
    border-color: transparent;
    outline-color: transparent;
}