

@mixin responsive($breakpoint) {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
        @content;
    }
}


// Smooth transition

@mixin transition($property) {
    transition: #{$property} 0.4s ease;
}